import { doc, getDoc } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const retrieveDetailsFromDb = async (user_ref) => {
  const userRefDoc = doc(db, `users/${user_ref}`);
  return getDoc(userRefDoc).then((data) => {
    if (!data.exists()) {
      return {};
    }
    return { ...data.data() };
  });
};
const handleRetrieveAuthorDetails = async (user_ref, employeesList) => {
  let details;
  const index = employeesList.findIndex((el) => el.doc_id === user_ref);
  if (index > -1) {
    details = employeesList[index];
  } else {
    details = await retrieveDetailsFromDb();
  }
  return details;
};

export default handleRetrieveAuthorDetails;
