import { FavoriteBorderOutlined, FavoriteOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import theme from 'src/theme';

const FavoritePostButton = ({ post, handleFavoriteItem }) => {
  FavoritePostButton.propTypes = {
    post: PropTypes.object,
    handleFavoriteItem: PropTypes.func
  };

  return (
    <Stack direction="row" gap={0.5}>
      <IconButton
        sx={{ lineHeight: 3 }}
        onClick={() => {
          handleFavoriteItem(post);
        }}
      >
        {post &&
        post.postDetails &&
        post.postDetails.favorite &&
        post.postDetails.favorite.state ? (
          <FavoriteOutlined
            sx={{
              fill: theme.palette.error.light,
              WebkitTransform: 'perspective(300px) rotateX(30deg)'
            }}
          />
        ) : (
          <FavoriteBorderOutlined
            sx={{
              fill: theme.palette.error.light
            }}
          />
        )}
      </IconButton>
      <Typography variant="body2" sx={{ lineHeight: 3, userSelect: 'none' }}>
        {post.favorite_sum === 1
          ? 'לייק אחד'
          : post.favorite_sum === 2
          ? 'שני לייקים'
          : post.favorite_sum > 2
          ? `${post.favorite_sum} לייקים`
          : '0 לייקים'}
      </Typography>
      {/* <Button variant="text">{post.favori/te_sum || '0 לייקים'}</Button> */}
    </Stack>
  );
};
export default FavoritePostButton;
