import { collection, getDocs } from '@firebase/firestore';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import getSidebarItems from '../useNavbarItems/getSidebarItems';
import { useSidebarItems } from '../useNavbarItems/useSidebarItems';
import { useSelectedOrg } from '../useSelectedOrg/useSelectedOrg';
import { useSnackbar } from '../useSnackbar/useSnackbar';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const RbacContext = createContext();

export const RbacProvider = ({ children }) => {
  RbacProvider.propTypes = {
    children: PropTypes.any
  };
  const [rbacDetails, setRbacDetails] = useState();
  const { snackbarState, setSnackbarState } = useSnackbar();
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const { setSidebarItems } = useSidebarItems();

  const getDepartmentsValues = async (orgId) => {
    // console.log(orgId);
    const departmentsRef = collection(db, `orgs/${orgId}/departments`);
    return getDocs(departmentsRef)
      .then((depts) => {
        if (depts.empty) {
          return [];
        }
        const temp = [];
        depts.docs.forEach((dept) => {
          temp.push({ ...dept.data(), department_id: dept.id });
        });
        return [...temp];
      })
      .catch((err) => {
        console.log(err);
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'error',
          msg: 'לא הצלחנו לשלוף את נתוני המחלקות, נא לנסות לטעון את הדף מחדש'
        });
        return [];
      });
  };

  const getRolesValues = async (orgId) => {
    const rolesRef = collection(db, `orgs/${orgId}/roles`);
    return getDocs(rolesRef)
      .then((roles) => {
        if (roles.empty) {
          return [];
        }
        const temp = [];
        roles.docs.forEach((role) => {
          temp.push({ ...role.data(), role_id: role.id });
        });
        return [...temp];
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };

  const retrieveAllRbacValues = async (orgId) => {
    const departments = await getDepartmentsValues(orgId);
    const roles = await getRolesValues(orgId);
    Promise.all([departments, roles])
      .then((res) => {
        // console.log(orgId, res);
        const sidebarItemsAfterRoles = getSidebarItems(
          currentUser,
          selectedOrg
        );
        setRbacDetails({ ...rbacDetails, departments: res[0], roles: res[1] });
        // console.log(res[1]);
        setSidebarItems(sidebarItemsAfterRoles);
      })
      .catch((err) => {
        setRbacDetails({ ...rbacDetails, departments: [], roles: [] });
        console.log(err);
      });
  };

  useEffect(() => {
    if (currentUser && currentUser.type === 'org') {
      retrieveAllRbacValues(currentUser.user_doc_id);
    } else if (
      currentUser &&
      currentUser.type === 'personal' &&
      selectedOrg &&
      (selectedOrg !== '' || selectedOrg !== 'null')
    ) {
      retrieveAllRbacValues(selectedOrg);
    }
  }, [currentUser, selectedOrg]);

  const currentRbacDetails = useMemo(
    () => ({ rbacDetails, setRbacDetails }),
    [rbacDetails, setRbacDetails]
  );
  return (
    <RbacContext.Provider value={currentRbacDetails}>
      {children}
    </RbacContext.Provider>
  );
};
export const useRbacDetails = () => useContext(RbacContext);
