import i18n from 'src/i18n';

const orgsTags = [
  { name: 'nursing', label: i18n.t('orgTags.nursing') },
  { name: 'frail', label: i18n.t('orgTags.frail') },
  { name: 'cognitive_frail', label: i18n.t('orgTags.cognitiveFrail') },
  { name: 'retirement', label: i18n.t('orgTags.retirement') }
];

export default orgsTags;
