import { collection, getDocs } from '@firebase/firestore';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Skeleton,
  Tab,
  Tabs
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import calcAmountOfEmployeesForPractice from 'src/utils/calcAmountOfEmployeesForPractice';
import DataTable from './DataTable';
import getEmployeeDetails from './getEmployeeDetails';

const PostSignsDialog = ({ post, open, onClose }) => {
  PostSignsDialog.propTypes = {
    post: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
  };
  const { employeesData } = useEmployeesData();
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();

  const [postSigns, setPostSigns] = useState();
  const [dataForTable, setDataForTable] = useState();
  const [activeView, setActiveView] = useState('did-sign');

  const handleRetrievePostSigns = () => {
    const { post_id, org_ref } = post;
    const signsDocRef = collection(
      db,
      `orgs/${org_ref}/community/${post_id}/signs`
    );
    getDocs(signsDocRef)
      .then(async (signSnap) => {
        if (signSnap.empty) {
          setPostSigns([]);
        } else {
          const signs = signSnap.docs.map(async (signDoc) => {
            const user_details = await getEmployeeDetails(
              signDoc.id,
              employeesData
            );
            return {
              ...signDoc.data(),
              sign_id: signDoc.id,
              user_details
            };
          });
          try {
            const resolvedPromise = await Promise.all(signs).catch((err) => {
              console.log(err);
              return [];
            });
            // console.log(resolvedPromise);
            setPostSigns(resolvedPromise);
          } catch (error) {
            console.log(error);
            setPostSigns([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setPostSigns([]);
      });
  };

  useEffect(() => {
    if (post) {
      handleRetrievePostSigns();
    }
  }, [post]);

  const tableHeadCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'שם העובד',
      sortable: true
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ת.ז',
      sortable: true
    },
    {
      id: 'signed_at',
      numeric: false,
      disablePadding: false,
      date: true,
      label: 'תאריך חתימה',
      sortable: true
    }
  ];
  const processSignsData = () => {
    if (postSigns) {
      const processed = postSigns.map((sign) => {
        const { user_details, signed_at, post_id, org_ref, user_id } = sign;
        // console.log(sign);
        return {
          signed_at,
          name: user_details
            ? `${user_details.first_name} ${user_details.last_name}`
            : 'אין מידע',
          id: user_details ? user_details.id : 'אין מידע',
          user_id,
          org_ref,
          post_id
        };
      });
      return processed;
    }
  };

  useEffect(() => {
    if (currentUser && selectedOrg && employeesData && postSigns) {
      // console.log(info);
      if (activeView === 'did-not-sign') {
        const t = calcAmountOfEmployeesForPractice(
          postSigns,
          currentUser,
          employeesData,
          selectedOrg,
          post.labels
        );
        const { allAuthorizedList } = t;
        const filtered = [];
        const { org_ref, post_id } = post;
        allAuthorizedList.forEach((authEmp) => {
          const { doc_id } = authEmp;
          const indexInSigned = postSigns.findIndex(
            (el) => el.user_id === doc_id
          );
          if (indexInSigned === -1) {
            const { id, first_name, last_name } = authEmp;
            filtered.push({
              id,
              name: `${first_name} ${last_name}`,
              org_ref,
              user_id: doc_id,
              post_id
            });
          }
        });
        // console.log(filtered);
        setDataForTable(filtered);
      } else {
        const processed = processSignsData();
        const t = calcAmountOfEmployeesForPractice(
          processed,
          currentUser,
          employeesData,
          selectedOrg,
          post.labels
        );
        setDataForTable(processed);
      }
    }
  }, [currentUser, selectedOrg, employeesData, postSigns, activeView]);

  const handleChangeView = (newView) => {
    setActiveView(newView);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>חתימות על הפוסט</DialogTitle>
      <Divider />
      <DialogContent>
        {post && postSigns ? (
          <>
            <Tabs
              value={activeView}
              onChange={(e, newView) => handleChangeView(newView)}
            >
              <Tab value="did-sign" label="חתמו">
                חתמו
              </Tab>
              <Tab value="did-not-sign" label="לא חתמו">
                לא חתמו
              </Tab>
            </Tabs>
            {postSigns.length > 0 ? (
              <DataTable
                items={dataForTable}
                tableHeadCells={tableHeadCells}
                info={post}
                key="post-signs-table"
              />
            ) : (
              'טרם פוסט זה נחתם'
            )}
          </>
        ) : (
          <>
            {[1, 2, 3].map((sklt) => (
              <Skeleton
                key={sklt}
                variant="rounded"
                sx={{ width: '100%', height: '52px', my: 1.5 }}
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostSignsDialog;
