import { uniqBy } from 'lodash';
import containsSomeLabels from './containsSomeLabels';
import getEmployeesInSpecificDepartment from './getEmployeesInSpecificDepartment';
import getUserDepartmentsWithSpecificPermission from './getUserDepartmentsWithSpecificPermission';

const calcAmountOfEmployeesForPractice = (
  signsList,
  currentUser,
  employeesData,
  selectedOrg,
  practiceLabels
) => {
  const { type } = currentUser;
  if (type === 'org') {
    const filteredWithLabels = employeesData.filter((el) =>
      containsSomeLabels(practiceLabels || [], [
        el.org_specific_details.job_title
      ])
    );
    return {
      authorizedListThatSigned: signsList,
      employeesCount: filteredWithLabels.length,
      uniqueSignedEmployeesCount: signsList.length,
      allAuthorizedList: filteredWithLabels
    };
  }
  if (type === 'personal') {
    const departmentsWithTheRightPermission =
      getUserDepartmentsWithSpecificPermission(
        'practices:checkSignatures',
        selectedOrg,
        currentUser
      );
    const employeesInTheseDepartments = departmentsWithTheRightPermission
      .map((dept) =>
        getEmployeesInSpecificDepartment(dept.department_id, employeesData)
      )
      .flat();
    const employeesUnique = uniqBy(employeesInTheseDepartments, 'doc_id');
    // console.log(practiceLabels);
    const filteredOnlyWithLabel = employeesUnique.filter((el) =>
      containsSomeLabels(practiceLabels || [], [
        el.org_specific_details.job_title
      ])
    );
    // since an employee can be in more than one dept
    const uniqueEmployeesThatSigned = [];
    filteredOnlyWithLabel.forEach((employee) => {
      const { employee_ref } = employee.org_specific_details;
      const index = signsList.findIndex((el) => el.user_id === employee_ref);
      if (index > -1) {
        // check if employee is approved
        const indexOfEmployee = employeesData.findIndex(
          (el) => el.doc_id === employee_ref
        );
        if (
          indexOfEmployee > -1 &&
          employeesData[indexOfEmployee] &&
          employeesData[indexOfEmployee].org_specific_details.status ===
            'approved'
        ) {
          // check if employee fot the right label
          uniqueEmployeesThatSigned.push({
            ...employee,
            ...signsList[index]
          });
        }
      }
    });
    return {
      authorizedListThatSigned: uniqueEmployeesThatSigned,
      employeesCount: filteredOnlyWithLabel.length,
      uniqueSignedEmployeesCount: uniqueEmployeesThatSigned.length,
      allAuthorizedList: filteredOnlyWithLabel
    };
  }
};

export default calcAmountOfEmployeesForPractice;
