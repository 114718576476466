import { DeleteForeverOutlined, MoreVertOutlined } from '@mui/icons-material';
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import { useState } from 'react';
import theme from 'src/theme';

export default function CommunityPostItemMoreMenu({
  post,
  handleOpenEditDialog,
  handleOpenDeletePostDialog
}) {
  CommunityPostItemMoreMenu.propTypes = {
    post: PropTypes.any,
    handleOpenEditDialog: PropTypes.func,
    handleOpenDeletePostDialog: PropTypes.func
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditPost = () => {
    handleClose();
  };
  return (
    <div>
      <IconButton
        id={`more-menu${post.post_id}-btn`}
        aria-controls={open ? `more-menu${post.post_id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id={`more-menu${post.post_id}`}
        aria-labelledby={`more-menu${post.post_id}-btn`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {/* <ListItemButton onClick={() => handleOpenEditDialog(post)}>
          <ListItemIcon sx={{ width: 'fit-content !important', minWidth: 40 }}>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary="עריכה" sx={{ minWidth: 60 }} />
        </ListItemButton> */}
        {/* <Divider sx={{ width: '85%', mx: 'auto' }} /> */}
        <ListItemButton
          onClick={() => {
            handleOpenDeletePostDialog(post);
            console.log(post);
          }}
        >
          <ListItemIcon sx={{ width: 'fit-content !important', minWidth: 40 }}>
            <DeleteForeverOutlined color="error" />
          </ListItemIcon>
          <ListItemText
            primary="מחיקה"
            sx={{ minWidth: 60, color: theme.palette.error.main }}
          />
        </ListItemButton>
      </Menu>
    </div>
  );
}
