import {
    Avatar,
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RenderFileComponent from 'src/components/Practices/ViewFileDialog/RenderFileComponent';
import theme from 'src/theme';

const AttachedFileViewDialog = ({
  post,
  open,
  onClose,
  handleOpenSignViewDialog
}) => {
  AttachedFileViewDialog.propTypes = {
    post: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleOpenSignViewDialog: PropTypes.func
  };

  const [avatar, setAvatar] = useState();
  const [fileDetails, setFileDetails] = useState();

  const getAvatarOfAuthor = (postDetails) => {
    // console.log(postDetails);
    if (postDetails && postDetails.author && postDetails.author.avatar) {
      setAvatar(postDetails.author.avatar);
    }
  };

  const getFileDetails = async () => {
    setFileDetails({
      ...fileDetails,
      ...post.file
    });
  };

  useEffect(() => {
    if (post) {
      if (post.file) {
        getFileDetails(post.org_ref, post.post_id);
      }
      if (post.postDetails) {
        getAvatarOfAuthor(post.postDetails);
      }
    }
  }, [post]);
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle
        sx={{
          position: 'static',
          top: 5,
          right: 5,
          backgroundImage: `linear-gradient(to bottom, rgb(216, 220, 255) 5%, ${theme.palette.background.paper} 85%)`
        }}
      >
        <List>
          <ListItem key={post.author_ref}>
            <ListItemAvatar>
              <Avatar src={avatar}>
                {post &&
                  post.postDetails &&
                  post.postDetails.author &&
                  post.postDetails.author.name[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                post &&
                post.postDetails &&
                post.postDetails.author &&
                post.postDetails.author.name
              }
              primaryTypographyProps={{
                fontWeight: 600
              }}
              secondary={
                <Stack sx={{ fontWeight: 'inherit', fontSize: 'inherit' }}>
                  <Typography
                    sx={{
                      fontWeight: 'inherit',
                      fontSize: 'inherit',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      wordWrap: 'break-word',
                      color: '#464f5e'
                    }}
                  >
                    {post.description}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'inherit',
                      fontSize: 'inherit',
                      color: 'inherit'
                    }}
                  >
                    {post.signs ? `${post.signs.length} חתמו` : 'אין חתימות'}
                  </Typography>
                </Stack>
              }
            />
          </ListItem>
        </List>
      </DialogTitle>
      <DialogContent>
        {post && post.file && fileDetails ? (
          <>
            {fileDetails.type === 'application/pdf' ? (
              <RenderFileComponent
                key={`${post.post_id}-${fileDetails.file_name}-${uniqueId(
                  'post-render'
                )}`}
                file_name={fileDetails.file_name}
                url={fileDetails.file_url}
              />
            ) : (
              <CardMedia
                src={fileDetails.file_url}
                sx={{
                  width: '100%',
                  height: '100%'
                }}
                alt={fileDetails.file_name}
              />
            )}
          </>
        ) : (
          <Skeleton variant="rounded" sx={{ width: '60vw', height: '70vh' }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleOpenSignViewDialog(post);
            onClose();
          }}
          color="primary"
          variant="contained"
        >
          חתימה
        </Button>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachedFileViewDialog;
