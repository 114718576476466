import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc
} from '@firebase/firestore';
import { DiamondOutlined, RedeemOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Icon,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import Lottie from 'lottie-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { db } from 'src/_firebase/firebase';
import chestLottie from 'src/assets/lottie/chest.json';
import { useCompensationEngine } from 'src/hooks/useCompensationEngine/useCompensationEngine';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import getCurrentTimestamp from 'src/utils/getCurrentTimestamp';
import incrementUserDiamond from 'src/utils/incrementUserDiamond';
import RedeemDiamondsDialog from './RedeemDiamondsDialog';
import './confetti.css';
import dailyLoginTreasuresData from './dailyLoginTreasuresData';
import getFormattedDates from './getFormattedDates';

const IntroCardForTodayClaim = ({
  userClaimedTodaysDiamonds,
  setUserClaimedTodaysDiamonds,
  loadingDiamond,
  setLoadingDiamond,
  dailyLoginsReward,
  setDailyLoginsReward,
  showOnlyDiamonds
}) => {
  IntroCardForTodayClaim.propTypes = {
    userClaimedTodaysDiamonds: PropTypes.bool,
    setUserClaimedTodaysDiamonds: PropTypes.func,
    loadingDiamond: PropTypes.bool,
    setLoadingDiamond: PropTypes.func,
    setDailyLoginsReward: PropTypes.func,
    dailyLoginsReward: PropTypes.object,
    showOnlyDiamonds: PropTypes.bool
  };
  const { snackbarState, setSnackbarState } = useSnackbar();
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const { compensationEngine, setCompensationEngine } = useCompensationEngine();
  const { currentUser, setCurrentUser } = useCurrentUserContext();
  const [isRedeemDialogOpened, setRedeemDialogOpened] = useState(false);

  /**
   *
   * @param {} user_doc_id
   * @returns timestamp, object contains updated firestore ts
   */
  const updateCurrentUserTimestamp = async (user_doc_id) => {
    const userRef = doc(db, `users/${user_doc_id}`);
    const timestamp = await setDoc(
      userRef,
      {
        timestamp: serverTimestamp()
      },
      {
        merge: true
      }
    )
      .then(async () => {
        const currentTimestamp = await getCurrentTimestamp(user_doc_id);
        // console.log('currentTimestamp', currentTimestamp);
        return currentTimestamp;
      })
      .catch((err) => console.log('err white update timestamp', err));
    return timestamp;
  };

  const checkIfLastLoginIsToday = async () => {
    const loginPrizesRef = collection(
      db,
      `users/${currentUser.user_doc_id}/loginPrizes`
    );
    const timestamp = await updateCurrentUserTimestamp(currentUser.user_doc_id);
    const q = query(loginPrizesRef, orderBy('login_date', 'desc'), limit(1));
    const lastLoginIsToday = await getDocs(q).then((loginsDocs) => {
      // console.log(loginsDocs.docs[0].data());
      if (loginsDocs.empty) {
        return false;
      }
      const { login_date } = loginsDocs.docs[0].data();
      if (login_date && login_date.seconds) {
        const today = moment(timestamp.seconds * 1000);
        return moment(today).isSame(moment(login_date.seconds * 1000), 'day');
      }
      return false;
    });
    // console.log(lastLoginIsToday);
    return lastLoginIsToday;
  };

  const saveUserLoginToDb = async (diamondsToIncrement) => {
    setLoadingDiamond(true);
    const userLoginsRef = collection(
      db,
      `users/${currentUser.user_doc_id}/logins`
    );
    const lastLoginIsToday = await checkIfLastLoginIsToday();
    // console.log('diamond', lastLoginIsToday);
    const loginPrizesCollectionRef = await getDocs(
      collection(db, `users/${currentUser.user_doc_id}/loginPrizes`)
    );
    const loginPrizesCollection = loginPrizesCollectionRef.size;
    // console.log(loginPrizesCollection);
    if (loginPrizesCollection >= 7) {
      const data = loginPrizesCollectionRef.docs.map((loginDoc) => ({
        ...loginDoc.data(),
        login_id: loginDoc.id
      }));
      const fomrattedDates = getFormattedDates(data);
      // const calced = weeklyRewardCalc(fomrattedDates);
      const timestamp = await updateCurrentUserTimestamp(
        currentUser.user_doc_id
      );

      const firstDayIsToday = moment(
        moment(timestamp.seconds * 1000).toDate()
      ).isSame(moment(fomrattedDates[0]), 'day');
      // console.log('firstDayIsToday', firstDayIsToday, timestamp);
      if (!firstDayIsToday) {
        // console.log('should continue');
        await Promise.all([
          loginPrizesCollectionRef.docs.map(async (docToDelete) =>
            deleteDoc(doc(db, docToDelete.ref.path))
          )
        ]).catch((err) => {
          console.log(err);
        });
      }
    }
    if (loginPrizesCollection >= 0 && !lastLoginIsToday) {
      const dataToSave = {
        login_date: serverTimestamp(),
        user_ref: currentUser.user_doc_id,
        diamonds_claimed: diamondsToIncrement
      };
      addDoc(
        collection(db, `users/${currentUser.user_doc_id}/loginPrizes`),
        dataToSave
      );
      addDoc(userLoginsRef, { ...dataToSave })
        .then(() => {
          const tempCompensation = { ...compensationEngine };
          let conditional =
            tempCompensation.userLogins && tempCompensation.userLogins.logins
              ? tempCompensation.userLogins.logins
              : [];
          conditional = [
            {
              user_ref: currentUser.user_doc_id,
              diamonds_claimed: diamondsToIncrement,
              login_date: new Date()
            },
            ...conditional
          ];
          setCompensationEngine(tempCompensation);
          // console.log(dailyLoginsReward);
          setDailyLoginsReward(
            dailyLoginsReward.day < 7
              ? dailyLoginTreasuresData[dailyLoginsReward.day]
              : dailyLoginTreasuresData[0]
          );
          // console.log('diamonds should add by==>', diamondsToIncrement);
          incrementUserDiamond(
            diamondsToIncrement,
            currentUser.user_doc_id,
            currentUser.diamonds
          )
            .then(() => {
              const temp = { ...currentUser };
              temp.diamonds =
                !temp.diamonds || temp.diamonds === 0
                  ? diamondsToIncrement
                  : temp.diamonds + diamondsToIncrement;
              setCurrentUser(temp);
              setUserClaimedTodaysDiamonds(true);
              setIsConfettiActive(true);
              setLoadingDiamond(false);
            })
            .catch((err) => {
              console.log(err);
              setUserClaimedTodaysDiamonds(true);
              setSnackbarState({
                ...snackbarState,
                open: true,
                severity: 'error',
                msg: 'לא הצלחנו להעניק לך את היהלומים, נסה שוב'
              });
              setLoadingDiamond(false);
            });
        })
        .catch((err) => {
          setUserClaimedTodaysDiamonds(true);
          console.log(err);
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'error',
            msg: 'לא הצלחנו להעניק לך את היהלומים, נסה שוב'
          });
        })
        .finally(() => setLoadingDiamond(false));
    }
    if (lastLoginIsToday) {
      // console.log('toad');
      setLoadingDiamond(false);
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'warning',
        msg: 'כבר קיבלת את היהלומים של היום'
      });
    }
  };

  const handleClaimTodaysDiamonds = async (diamondsToIncrement) => {
    // console.log(diamondsToIncrement);
    saveUserLoginToDb(diamondsToIncrement);
  };

  const handleAnimationEnd = () => {
    setIsConfettiActive(false);
  };

  const handleOpenRedeemDiamondsDialog = () => {
    setRedeemDialogOpened(true);
  };
  const handleCloseRedeemDiamondsDialog = () => {
    setRedeemDialogOpened(false);
  };

  return (
    <>
      <Card sx={{ backgroundColor: theme.palette.primary.light }} elevation={1}>
        <CardContent sx={{ width: '100%' }}>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row'
            }}
            sx={{ alignItems: 'center', justifyContent: 'center' }}
            gap={2}
          >
            <Lottie
              style={{
                width: '60% !important',
                height: '90px',
                minWidth: '110px',
                transform: 'scale(1.4) translateX(5px)',
                zIndex: 1,
                position: 'relative',
                //   float: 'right',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8
              }}
              autoPlay
              loop={1}
              animationData={chestLottie}
            />
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.primary.contrastText
              }}
            >
              <Typography sx={{ fontSize: '1.5rem' }}>היהלומים שלי</Typography>
              <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
                {currentUser && currentUser.diamonds >= 0
                  ? Math.floor(currentUser.diamonds)
                  : '--'}
              </Typography>
            </Stack>
          </Stack>
          {!showOnlyDiamonds && (
            <Stack
              sx={{ width: '100%', position: 'relative' }}
              direction={{
                xs: 'column',
                sm: 'row'
              }}
              justifyContent="space-around"
              alignItems="center"
            >
              <Stack
                sx={{ color: theme.palette.primary.contrastText }}
                gap={4}
                alignItems="flex-start"
                justifyContent="space-around"
              >
                <Typography variant="h3">
                  {!userClaimedTodaysDiamonds
                    ? 'נראה שעוד לא קיבלת את היהלומים של היום'
                    : 'יפה! שמור על רצף כניסות כדי לקבל יותר יהלומים'}
                </Typography>
                <Button
                  endIcon={<DiamondOutlined />}
                  variant="outlined"
                  sx={{
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                    boxShadow: theme.shadows[8],
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText
                    }
                  }}
                  disabled={!dailyLoginsReward}
                  onClick={() => {
                    handleClaimTodaysDiamonds(
                      dailyLoginsReward.day === 1 && userClaimedTodaysDiamonds
                        ? dailyLoginTreasuresData[dailyLoginsReward.day]
                            .diamonds
                        : dailyLoginsReward.day === 1 &&
                          !userClaimedTodaysDiamonds
                        ? dailyLoginTreasuresData[0].diamonds
                        : dailyLoginsReward.day < 7 && dailyLoginsReward.day > 1
                        ? dailyLoginTreasuresData[dailyLoginsReward.day]
                            .diamonds
                        : dailyLoginTreasuresData[0].diamonds
                    );
                  }}
                >
                  {dailyLoginTreasuresData && dailyLoginsReward ? (
                    `לחץ לקבלת ${
                      dailyLoginsReward.day === 1 && userClaimedTodaysDiamonds
                        ? dailyLoginTreasuresData[dailyLoginsReward.day]
                            .diamonds
                        : dailyLoginsReward.day === 1 &&
                          !userClaimedTodaysDiamonds
                        ? dailyLoginTreasuresData[0].diamonds
                        : dailyLoginsReward.day < 7 && dailyLoginsReward.day > 1
                        ? dailyLoginTreasuresData[dailyLoginsReward.day]
                            .diamonds
                        : dailyLoginTreasuresData[0].diamonds || '-'
                    }`
                  ) : (
                    <Skeleton sx={{ width: '55px' }} variant="text" />
                  )}
                </Button>
                {isConfettiActive && (
                  <div
                    className="confetti-container"
                    onAnimationEnd={handleAnimationEnd}
                  >
                    {/* Render multiple instances of the DiamondConfetti component */}
                    <Stack
                      direction="column-reverse"
                      sx={{ alignItems: 'center' }}
                    >
                      <Stack direction="row">
                        <Icon>
                          <DiamondOutlined />
                        </Icon>
                        <Icon>
                          <DiamondOutlined />
                        </Icon>
                        <Icon>
                          <DiamondOutlined />
                        </Icon>
                      </Stack>
                      <Stack direction="row">
                        <Icon>
                          <DiamondOutlined />
                        </Icon>
                        <Icon>
                          <DiamondOutlined />
                        </Icon>
                      </Stack>
                    </Stack>
                    {/* Add more instances of the DiamondConfetti component as needed */}
                  </div>
                )}
              </Stack>
            </Stack>
          )}
        </CardContent>
        {process.env.REACT_APP_DEV === 'true' && !showOnlyDiamonds && (
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            {currentUser.diamonds > 500 ? (
              <Button
                sx={{ color: theme.palette.primary.contrastText }}
                variant="text"
                startIcon={<RedeemOutlined />}
                onClick={() => handleOpenRedeemDiamondsDialog()}
              >
                להמרת היהלומים לשוברי מתנה
              </Button>
            ) : (
              <Typography sx={{ color: theme.palette.primary.contrastText }}>
                * תוכל להמיר לשוברי מתנה כשתגיע ל-500 יהלומים
              </Typography>
            )}
          </CardActions>
        )}
      </Card>
      {isRedeemDialogOpened && (
        <RedeemDiamondsDialog
          isRedeemDialogOpened={isRedeemDialogOpened}
          onClose={handleCloseRedeemDiamondsDialog}
        />
      )}
    </>
  );
};

export default IntroCardForTodayClaim;
