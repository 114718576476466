import {
  addDoc,
  collection,
  doc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from '@firebase/firestore';
import { Grid, Paper } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { db, storage } from 'src/_firebase/firebase';
import jobsTitles from 'src/common/jobsTitles';
import AddNewPostCard from 'src/components/KnowledgeCommunity/InternalCommunity/AddNewPostCard';
import AttachedFileViewDialog from 'src/components/KnowledgeCommunity/InternalCommunity/AttachedFileViewDialog';
import CommunityPostsList from 'src/components/KnowledgeCommunity/InternalCommunity/CommunityPostsList';
import InternalOrgCommunityToolbar from 'src/components/KnowledgeCommunity/InternalCommunity/InternalOrgCommunityToolbar';
import OrgCommunityPostsTabs from 'src/components/KnowledgeCommunity/InternalCommunity/OrgCommunityPostsTabs';
import NewPostsUpdatesCard from 'src/components/KnowledgeCommunity/InternalCommunity/RecentUpdates/NewPostsUpdatesCard';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import getOrgRef from 'src/utils/getOrgRef';
import isAuthorized from 'src/utils/isAuthorized';

const handleUploadFileToStorage = async (path, file, metadata = {}) => {
  const storageRef = ref(storage, path);
  return uploadBytes(storageRef, file, {
    customMetadata: {
      ...metadata
    }
  })
    .then(() => ({ state: 'success' }))
    .catch((err) => {
      console.log(err);
      return { state: 'err', err };
    });
};

const handleSaveNewPostToDb = async (
  postValues,
  org_ref,
  author_ref,
  setSnackbarState,
  snackbarState
) => {
  const orgCommunityRef = collection(db, `orgs/${org_ref}/community`);
  const { file, file_name, ...rest } = postValues;

  const dataToSave = file
    ? {
        ...rest,
        file: {
          file_name: file_name || file.name,
          type: file.type
        }
      }
    : { ...rest };
  const timestamp = serverTimestamp();
  return addDoc(orgCommunityRef, {
    ...dataToSave,
    created_at: timestamp,
    last_modified: timestamp,
    author_ref,
    org_ref
  })
    .then(async (res) => {
      if (file) {
        const uploadState = await handleUploadFileToStorage(
          `orgs/${org_ref}/community/${res.id}`,
          file.file,
          {
            original_file_name: file.name
          }
        );
        if (uploadState && uploadState.state === 'success') {
          return { state: 'success', post_id: res.id };
        }
        if (uploadState && uploadState.state === 'err') {
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'error',
            msg: 'הפוסט נשמר אך לא הצלחנו להעלות את הקובץ, נא לנסות שוב'
          });
          return { state: 'err', post_id: res.id, err: uploadState.err };
        }
      }
      return { state: 'success', post_id: res.id };
    })
    .catch((err) => {
      console.log(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לצור את הפוסט, נא לנסות שוב'
      });
      return { state: 'err', err };
    });
};

const InternalOrgCommunity = () => {
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const { snackbarState, setSnackbarState } = useSnackbar();
  // const [shownPosts, setShownPosts] = useState();
  const [tabValue, setTabValue] = useState('regular');
  const [communityItems, setCommunityItems] = useState();
  const [attachedFileToView, setAttachedFileToView] = useState();
  const [signViewIsOpen, setSignViewIsOpen] = useState();

  const handleSaveNewPost = async (newPostValues, setNewPostValues) => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const details = await handleSaveNewPostToDb(
      newPostValues,
      org_ref,
      currentUser.user_doc_id,
      setSnackbarState,
      snackbarState
    );
    const { state, post_id } = details;
    if (state === 'success') {
      setNewPostValues({ description: '', signature_required: false });
      const temp = [...communityItems];
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'success',
        msg: 'הפרסום נשמר בהצלחה'
      });
      const { file, file_name, ...rest } = newPostValues;

      const dataToSave = file
        ? {
            ...rest,
            file: {
              file_name: file_name || file.name,
              type: file.type
            }
          }
        : { ...rest };
      if (dataToSave.signature_required && tabValue === 'signature_required') {
        temp.unshift({
          ...dataToSave,
          created_at: {
            seconds: moment().unix()
          },
          post_id,
          author_ref: currentUser.user_doc_id,
          org_ref
        });
      }
      if (!dataToSave.signature_required && tabValue === 'regular') {
        temp.unshift({
          ...dataToSave,
          created_at: {
            seconds: moment().unix()
          },
          post_id,
          author_ref: currentUser.user_doc_id,
          org_ref
        });
      }
      setCommunityItems(temp);
    } else if (state === 'err') {
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לשמור את הפרסום, נא לנסות שוב!'
      });
    }
  };

  // const filterPosts = (viewToKeep, list) =>
  //   list.filter((el) => el.signature_required !== (viewToKeep === 'regular'));

  const retrieveSelectedOrgInternalcommunity = async () => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const orgCommunityRef = collection(db, `orgs/${org_ref}/community`);
    const q = query(
      orgCommunityRef,
      where(
        'signature_required',
        '==',
        Boolean(tabValue === 'signature_required')
      ),
      orderBy('last_modified', 'desc')
    );
    getDocs(q).then((snap) => {
      if (snap.empty) {
        setCommunityItems([]);
        // setShownPosts([]);
      } else {
        const items = snap.docs.map((communityItem) => ({
          ...communityItem.data(),
          post_id: communityItem.id,
          labels: communityItem.data().labels || jobsTitles.map((el) => el.name)
        }));
        // const filtered = filterPosts(tabValue, items);
        // setShownPosts(filtered);
        setCommunityItems(items);
      }
    });
  };

  const handleOpenSignViewDialog = (post) => {
    setSignViewIsOpen(post);
  };
  const handleChangeTabValue = (newView) => {
    // const filtered = filterPosts(newView, communityItems);
    // setShownPosts(filtered);
    setTabValue(newView);
    setCommunityItems();
    retrieveSelectedOrgInternalcommunity();
  };

  useEffect(() => {
    if (currentUser && tabValue) {
      if (currentUser.type === 'personal') {
        if (selectedOrg && selectedOrg !== 'null') {
          retrieveSelectedOrgInternalcommunity();
        }
      } else {
        retrieveSelectedOrgInternalcommunity();
      }
    }
  }, [selectedOrg, currentUser, tabValue]);

  const handleSaveFavoritePostToDb = async (
    post_id_to_favorite,
    newFavoriteState
  ) => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const orgCommunityRef = doc(
      db,
      `orgs/${org_ref}/community/${post_id_to_favorite}/likes/${currentUser.user_doc_id}`
    );
    setDoc(orgCommunityRef, {
      ...newFavoriteState,
      last_modified: serverTimestamp(),
      user_ref: currentUser.user_doc_id,
      org_ref:
        currentUser.type === 'personal' ? selectedOrg : currentUser.user_doc_id
    }).catch((err) => {
      console.log(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לשמור את הלייק שלך'
      });
    });
    const postRef = doc(db, `orgs/${org_ref}/community/${post_id_to_favorite}`);
    const incVal = Number(newFavoriteState.state ? 1 : -1);
    await updateDoc(postRef, {
      favorite_sum: increment(incVal)
    }).catch((err) => {
      console.log(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לעדכן את הלייק שלך'
      });
    });
  };

  const handleFavoriteItem = (postToFavorite) => {
    const temp = [...communityItems];
    const index = temp.findIndex((el) => el.post_id === postToFavorite.post_id);
    let newFavoriteState = {};
    if (index > -1) {
      newFavoriteState = {
        state: temp[index].favorite ? !temp[index].favorite.state : true,
        last_modified: {
          seconds: moment().unix()
        }
      };
      console.log(temp[index]);
      temp[index].favorite = newFavoriteState;
      // if(newFavoriteState.state){
      //   // this is the case that the post is liked now
      // } else {
      //   // this is the case that the post is liked
      // }
      if (temp[index].favorite_sum === 0) {
        temp[index].favorite_sum += 1;
      } else if (
        temp[index].favorite_sum > 0 &&
        temp[index].favorite &&
        !temp[index].favorite.state
      ) {
        temp[index].favorite_sum -= 1;
      } else if (
        temp[index].favorite_sum > 0 &&
        temp[index].favorite &&
        temp[index].favorite.state
      ) {
        temp[index].favorite_sum -= 1;
      } else if (!temp[index].favorite_sum) {
        temp[index].favorite_sum = 1;
      } else {
        temp[index].favorite_sum += 1;
      }
    }
    setCommunityItems(temp);
    handleSaveFavoritePostToDb(postToFavorite.post_id, newFavoriteState);
  };
  const handleRetrieveFileUrl = async (postToGet) => {
    // console.log(postToGet);
    const { post_id, org_ref } = postToGet;
    const storageRef = ref(storage, `orgs/${org_ref}/community/${post_id}`);
    return getDownloadURL(storageRef)
      .then((res) => {
        const temp = [...communityItems];
        const index = temp.findIndex((el) => el.post_id === post_id);
        if (index > -1) {
          if (temp[index].file) {
            temp[index].file.file_url = res;
          }
          setCommunityItems(temp);
          return res;
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  const handleViewCommunityAttachedFile = async (postToView) => {
    // console.log(postToView);
    const { file } = postToView;
    if (file) {
      if (file.file_url) {
        setAttachedFileToView({
          ...postToView,
          file: { ...postToView.file, file_url: file.file_url }
        });
      } else {
        const urlRes = await handleRetrieveFileUrl(postToView);
        // console.log(res);
        setAttachedFileToView({
          ...postToView,
          file: { ...postToView.file, file_url: urlRes }
        });
      }
    } else {
      handleOpenSignViewDialog(postToView);
    }
  };

  const handleCloseAttachedFileViewDialog = () => {
    setAttachedFileToView();
  };
  return (
    <>
      <Paper
        sx={{
          p: 3,
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[0]
        }}
      >
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'center' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <InternalOrgCommunityToolbar />
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item container xs={12} md={8} spacing={2}>
              {isAuthorized(currentUser, 'practices:write', selectedOrg) && (
                <Grid item xs={12}>
                  <AddNewPostCard handleSaveNewPost={handleSaveNewPost} />
                </Grid>
              )}
              <Grid item xs={12}>
                <OrgCommunityPostsTabs
                  tabValue={tabValue}
                  handleChangeTabValue={handleChangeTabValue}
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <CommunityPostsList
                  handleViewCommunityAttachedFile={
                    handleViewCommunityAttachedFile
                  }
                  signViewIsOpen={signViewIsOpen}
                  setSignViewIsOpen={setSignViewIsOpen}
                  attachedFileToView={attachedFileToView}
                  setAttachedFileToView={setAttachedFileToView}
                  handleFavoriteItem={handleFavoriteItem}
                  posts={communityItems}
                  setPosts={setCommunityItems}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                height: 'fit-content !important'
              }}
              item
              container
              xs={12}
              md={4}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sx={{
                  height: 'fit-content !important'
                }}
              >
                <NewPostsUpdatesCard
                  handleViewCommunityAttachedFile={
                    handleViewCommunityAttachedFile
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {attachedFileToView && (
        <AttachedFileViewDialog
          open={Boolean(attachedFileToView)}
          onClose={handleCloseAttachedFileViewDialog}
          post={attachedFileToView}
          handleOpenSignViewDialog={handleOpenSignViewDialog}
        />
      )}
    </>
  );
};

export default InternalOrgCommunity;
