import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'src/components/shared/AccessibilityComponent/index.css';
import App from './App';
import { MagnificationProvider } from './hooks/useMagnification/useMagnification';
import { SidebarItemsProvider } from './hooks/useNavbarItems/useSidebarItems';
import { NotifcationsProvider } from './hooks/useNotificationsEngine/useNotificationsEngine';
import { SelectedOrgProvider } from './hooks/useSelectedOrg/useSelectedOrg';
import { StorageProvider } from './hooks/useStorage/useUserStorage';
import { CurrentUserProvider } from './hooks/useUserContext/UserContext';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <MagnificationProvider>
      <CurrentUserProvider>
        <SelectedOrgProvider>
          <NotifcationsProvider>
            <SidebarItemsProvider>
              <StorageProvider>
                <App />
              </StorageProvider>
            </SidebarItemsProvider>
          </NotifcationsProvider>
        </SelectedOrgProvider>
      </CurrentUserProvider>
    </MagnificationProvider>
    {/* </MapProvider> */}
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.register();
