const routesConst = {
  app: 'app',
  account: 'account',
  patients: 'patients',
  dashboard: 'dashboard',
  orders: 'orders',
  employees: 'employees',
  settings: 'settings',
  error404: '404',
  login: 'login',
  register: 'register',
  coursewares: 'coursewares',
  org: 'org',
  user: 'user',
  practices: 'practices',
  results: 'results',
  search: 'search',
  privacy: 'privacy',
  forms: 'forms',
  mohCode: 'mohCode',
  mohBranches: 'mohBranches',
  resetPassword: 'resetPassword',
  resume: 'resume',
  preview: 'preview',
  newContact: 'newContact',
  mohReports: 'mohReports',
  tickets: 'tickets',
  contact: 'contact',
  vision: 'vision',
  familyLogin: 'family-login',
  familyAccount: 'family',
  compensation: 'compensation',
  leaderboard: 'leaderboard',
  roles: 'roles',
  jobs: 'jobs',
  knowledgeCommunity: 'knowledge-community',
  departments: 'departments',
  onboarding: 'onboarding',
  thankyou: 'thankyou',
  redeemDiamonds: 'redeem',
  medicalAssessment: 'medical-assessment',
  community: 'community',
  mgdalorCommunity: 'mgdalor-community',
  internal: 'internal',
  blog: 'blog',
  aboutMgdalor: 'ourSystem',
  evaluation: 'evaluation'
};

export default routesConst;
