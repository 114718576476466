import {
  ArrowBackOutlined,
  Diversity1Outlined,
  HubOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Hidden,
  Paper,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import Lottie from 'lottie-react';
import avatar from 'src/assets/knowledgeCommunity/avatar.png';
import avatars from 'src/assets/knowledgeCommunity/avatars.png';
import team from 'src/assets/knowledgeCommunity/team.png';
import arrowLottie from 'src/assets/lottie/arrow.json';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import routesConst from 'src/routesConst';
import theme from 'src/theme';

const CommunityIntroScheme = () => {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        mx: 'auto',
        p: 2,
        '&:after': {
          position: 'absolute',
          content: '""',
          width: 25,
          height: 25,
          background: 'teal',
          left: 0,
          top: 5,
          borderRadius: '50%'
        }
      }}
    >
      <Box
        key="bg-dashed-cicle"
        sx={{
          // height: '100%',
          width: '80%',
          minWidth: 150,
          aspectRatio: '1/1',
          borderRadius: '50%',
          borderBlockStart: `3px dashed ${theme.palette.primary.light}`,
          borderInlineStart: `3px dashed ${theme.palette.primary.light}`,
          position: 'absolute',
          zIndex: 0
        }}
      />
      <Card
        elevation={0}
        sx={{
          position: 'relative',
          zIndex: 1,
          right: '-60px',
          top: 20,
          background: '#ff8b76',
          width: 120,
          aspectRatio: '1/1',
          display: 'flex',
          alignItems: 'center'
        }}
        key="tomato-card"
      >
        <img
          alt="team"
          src={avatars}
          style={{
            width: '100%',
            height: '100%',
            maxHeight: 100
          }}
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          position: 'relative',
          zIndex: 1,
          left: matchMdDown ? '15%' : '60%',
          top: matchMdDown ? -10 : 20,
          background: '#fbcfff',
          width: 180,
          aspectRatio: '1/1',
          display: 'flex',
          alignItems: 'flex-end'
        }}
        key="aqua-card"
      >
        <img
          alt="team"
          src={team}
          style={{
            width: '100%',
            height: '100%',
            maxHeight: 140
          }}
        />
      </Card>
      <Box
        sx={{
          position: 'absolute',
          width: 15,
          height: 15,
          background: '#ff8b76',
          left: 'calc(80% - 25px)',
          top: '50px',
          borderRadius: '50%',
          zIndex: 1
        }}
        key="tomato-circle"
      />
      <Hidden smDown>
        <Box
          sx={{
            width: '100%',
            // boxSizing: 'content-box',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            left: '-5',
            bottom: '25%'
          }}
          key="arrow-box"
        >
          <Lottie
            animationData={arrowLottie}
            autoPlay
            loop={5}
            style={{
              width: '80px',
              position: 'absolute',
              // left: '-10%',
              height: '80px',
              transform: 'rotate(25deg)',
              filter: 'opacity(0.4)'
            }}
          />
        </Box>
      </Hidden>
      <Hidden smDown>
        <Card
          elevation={0}
          sx={{
            position: 'relative',
            zIndex: 1,
            left: 25,
            top: 20,
            background: '#50d8c5',
            width: 120,
            aspectRatio: '1/1.2',
            display: 'flex',
            alignItems: 'center'
          }}
          key="teal-card"
        >
          <img
            alt="avatar"
            src={avatar}
            style={{
              width: '120%',
              height: '120%',
              maxHeight: 130
            }}
          />
        </Card>
      </Hidden>
      <Box
        sx={{
          position: 'absolute',
          width: 18,
          height: 18,
          // background: '#42dfdf',
          background: 'pink',
          right: 'calc(60% - 25px)',
          bottom: 150,
          borderRadius: '50%',
          zIndex: 1
        }}
        key="aqua-circle"
      />
      <Card
        sx={{
          boxShadow: `0 0 50px 2px ${alpha(theme.palette.primary.light, 0.3)}`,
          maxWidth: 320,
          minWidth: 160,
          position: 'absolute',
          right: '12%',
          bottom: '5%',
          borderRadius: 6,
          zIndex: 3
        }}
        key="network-and-grow-card"
      >
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '0 !important'
          }}
        >
          <Typography sx={{ m: 1 }}>תכיר אנשים ותתפתח</Typography>
          <Box
            sx={{
              background: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
              p: 1,
              borderRadius: 1,
              my: 1,
              mx: 2
            }}
          >
            <HubOutlined sx={{ fill: 'white', fontSize: '16px' }} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const CommunityIntroPage = () => {
  const { selectedOrg } = useSelectedOrg();
  return (
    <Paper
      sx={{
        backgroundImage: `radial-gradient(circle 350px at -50px 0%, ${alpha(
          theme.palette.primary.main,
          0.12
        )} 50%, ${alpha(theme.palette.secondary.light, 0.025)})`,
        minHeight: 'calc(100vh - 64px)'
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={5} md={4}>
            <Typography
              variant="h1"
              component="p"
              sx={{
                lineHeight: 2,
                maxWidth: 420,
                color: theme.palette.primary.dark
              }}
            >
              המקום שלך לרכוש ידע ולקבל תמורת הידע שלך
            </Typography>
            <Stack
              direction={{ xs: 'column-reverse', sm: 'row' }}
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Button
                variant="contained"
                color="primary"
                href={`${routesConst.community}/mgdalor`}
                endIcon={<Diversity1Outlined />}
              >
                קהילת מגדלור
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                href={`${routesConst.community}/internal`}
                endIcon={<ArrowBackOutlined />}
                disabled={!selectedOrg || selectedOrg === 'null'}
              >
                קהילה ארגונית
              </Button>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={7}
            sx={{ p: 2, display: 'flex', justifyContent: 'center' }}
          >
            <CommunityIntroScheme />
          </Grid>
          {/* <Grid item xs={12} sx={{ mb: 5, mt: 6 }} container>
            <Grid
              item
              xs={12}
              sm={5}
              md={7}
              sx={{ p: 2, display: 'flex', justifyContent: 'center' }}
            >
              <CommunityIntroScheme />
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Typography
                variant="h1"
                component="p"
                sx={{
                  lineHeight: 2,
                  maxWidth: 420,
                  color: theme.palette.primary.dark
                }}
              >
                קהילה אחת
                <br />
                מלא יתרונות
              </Typography>
              <CommunityVerticalTimeline />
            </Grid>
          </Grid> */}
        </Grid>
      </Container>
    </Paper>
  );
};
export default CommunityIntroPage;
