import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { isUndefined, uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import jobsTitles from 'src/common/jobsTitles';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useRbacDetails } from 'src/hooks/useRBAC/useRBAC';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import compareRolesOrders from 'src/utils/compareRolesOrders';
import containsSomeLabels from 'src/utils/containsSomeLabels';
import getUserJobTitle from 'src/utils/getUserJobTitle';
import handleRetrievePostDetails from '../utils/handleRetrievePostDetails';
import AttachedFileList from './AttachedFileList';
import CommunityPostItemMoreMenu from './CommunityPostItem/CommunityPostItemMoreMenu';
import FavoritePostButton from './FavoritePostButton';

const CommunityPostItemCard = ({
  post,
  handleFavoriteItem,
  handleViewCommunityAttachedFile,
  setEditPostOpen,
  setDeletePostOpen,
  handleOpenPostSignaturesList
}) => {
  CommunityPostItemCard.propTypes = {
    post: PropTypes.object,
    handleFavoriteItem: PropTypes.func,
    handleViewCommunityAttachedFile: PropTypes.func,
    setEditPostOpen: PropTypes.func,
    setDeletePostOpen: PropTypes.func,
    handleOpenPostSignaturesList: PropTypes.func
  };
  const { employeesData } = useEmployeesData();
  const { selectedOrg } = useSelectedOrg();
  const { currentUser } = useCurrentUserContext();
  const { rbacDetails } = useRbacDetails();
  const [anchorEl, setAnchorEl] = useState(null);

  const isMyPost =
    currentUser && post && currentUser.user_doc_id === post.author_ref;
  const isOrg = currentUser && currentUser.type === 'org';

  const [postDetails, setPostDetails] = useState();
  const [signaturePhrase, setSignaturePhrase] = useState();

  const handleRetrieveDetails = async () => {
    const data = await handleRetrievePostDetails(
      currentUser,
      employeesData,
      post,
      selectedOrg,
      postDetails
    );
    const { userSignedPhrase, ...rest } = data;
    setSignaturePhrase(userSignedPhrase);
    setPostDetails(rest);
  };

  useEffect(() => {
    if (post && employeesData && currentUser && !postDetails) {
      handleRetrieveDetails();
    }
  }, [post, employeesData, currentUser, postDetails]);

  const getAvatarOfAuthor = () => {
    // console.log(postDetails);
    if (postDetails && postDetails.author && postDetails.author.avatar) {
      return postDetails.author.avatar;
    }
    return null;
  };

  const getTimeOfPost = (timeInSec) => {
    if (!timeInSec) {
      return 'הרגע';
    }
    const isSameMinute = moment(moment(timeInSec * 1000).toDate()).isSame(
      moment().toDate(),
      'minutes'
    );
    if (isSameMinute) {
      return moment(post.created_at.seconds * 1000)
        .startOf('m')
        .fromNow();
    }
    const isSameHour = moment(moment(timeInSec * 1000).toDate()).isSame(
      moment().toDate(),
      'hour'
    );
    if (isSameHour) {
      return moment(post.created_at.seconds * 1000)
        .startOf('mm')
        .fromNow();
    }
    const isSameDay = moment(moment(timeInSec * 1000).toDate()).isSame(
      moment().toDate(),
      'day'
    );
    if (isSameDay) {
      moment(post.created_at.seconds * 1000)
        .startOf('day')
        .fromNow();
    }
    return moment(post.created_at.seconds * 1000).format('DD/MM/YYYY');
  };
  const handleFavoritePostItem = (postToFavorite) => {
    handleFavoriteItem(postToFavorite);
    const temp = { ...postDetails };
    temp.favorite = {
      state: temp.favorite ? !temp.favorite.state : true, // if it does not exist then its the first time user likes this post
      last_modified: {
        seconds: moment().unix()
      }
    };
    setPostDetails(temp);
  };

  const getSignCountPhrase = () => {
    if (postDetails && postDetails.signCount) {
      if (postDetails.signCount === 1) {
        return 'חתימה אחת';
      }
      if (postDetails.signCount === 2) {
        return 'שתי חתימות';
      }
      if (postDetails.signCount > 2) {
        // console.log(postDetails.signCount);
        return `${postDetails.signCount} חתימות`;
      }
      return 'אין חתימות';
    }
    return 'אין חתימות';
  };

  const isUserRequiredToSign = () => {
    const { employee_work_places, type } = currentUser;
    if (postDetails) {
      if (postDetails.labels) {
        if (currentUser && type === 'personal') {
          if (employee_work_places) {
            const index = employee_work_places.findIndex(
              (el) => el.org_ref === postDetails.org_ref
            );
            if (index > -1) {
              const userJobTitle = getUserJobTitle(currentUser, selectedOrg);
              const labels = userJobTitle ? [userJobTitle] : [];
              const doesContain = containsSomeLabels(
                postDetails.labels || jobsTitles,
                labels
              );
              return doesContain;
            }
            return false;
          }
          return false;
        }
        if (currentUser && type === 'org') {
          return true;
        }
      }

      return true;
    }
  };

  const handleOpenEditDialog = (postToEdit) => {
    setEditPostOpen(postToEdit);
  };

  const handleOpenDeletePostDialog = (postToDelete) => {
    setDeletePostOpen(postToDelete);
  };

  const compareRolesOrdersOfAnyOfTheDepartments = () => {
    const { author_ref } = post;
    const { user_doc_id } = currentUser;

    if (employeesData) {
      const indexOfAuthorInEmployees = employeesData.findIndex(
        (el) => el.doc_id === author_ref
      );
      const indexOfCurrentUserInEmployees = employeesData.findIndex(
        (el) => el.doc_id === user_doc_id
      );
      if (indexOfAuthorInEmployees > -1 && indexOfCurrentUserInEmployees > -1) {
        const currentEmployee = employeesData[indexOfCurrentUserInEmployees];
        const authorEmployee = employeesData[indexOfAuthorInEmployees];
        // console.log(currentEmployee);
        if (
          currentEmployee.org_specific_details &&
          currentEmployee.org_specific_details.rbac
        ) {
          const isHigherInAnyDepartment =
            currentEmployee.org_specific_details.rbac.map((dept) =>
              compareRolesOrders(
                currentEmployee.org_specific_details.rbac,
                authorEmployee.org_specific_details.rbac,
                dept.department_id,
                rbacDetails
              )
            );
          console.log(isHigherInAnyDepartment);
          return isHigherInAnyDepartment.some((el) => el);
        }
        return false;
      }
      return false;
    }
    return false;
  };

  return (
    <>
      <Grid
        key={uniqueId(`${post ? post.author_ref : 'skeleton'}-Grid-post`)}
        item
        xs={12}
      >
        <Card
          key={uniqueId(`${post ? post.author_ref : 'skeleton'}-post`)}
          elevation={0}
        >
          <CardHeader
            avatar={
              postDetails && postDetails.author ? (
                <Avatar
                  aria-label={`post-${post ? post.author_ref : 'skeleton'}-${
                    post.post_id
                  }`}
                  src={getAvatarOfAuthor()}
                >
                  {postDetails &&
                  postDetails.author &&
                  postDetails.author &&
                  postDetails.author.name
                    ? postDetails.author.name
                    : post.author_ref}
                </Avatar>
              ) : (
                <Skeleton
                  variant="rounded"
                  sx={{ width: '100%', height: '100%' }}
                />
              )
            }
            action={
              <CommunityPostItemMoreMenu
                post={post}
                key={`${uniqueId('more-menu')}-${post.post_id}-component`}
                anchorEl={anchorEl}
                handleOpenEditDialog={handleOpenEditDialog}
                handleOpenDeletePostDialog={handleOpenDeletePostDialog}
              />
            }
            title={
              postDetails && postDetails.author && postDetails.author.name ? (
                `${postDetails.author.name}`
              ) : (
                <Skeleton variant="text" width={100} />
              )
            }
            titleTypographyProps={{
              fontWeight: 600
            }}
            subheader={getTimeOfPost(post.created_at.seconds)}
          />
          <CardContent sx={{ pb: 0, pt: 0.5 }}>
            {post ? (
              <Typography variant="body1" color="text">
                {post.description}
              </Typography>
            ) : (
              <Skeleton variant="text" width={180} />
            )}
            {post ? (
              <AttachedFileList
                infoVisible={false}
                secondaryPhrase={signaturePhrase}
                deleteIconVisible={false}
                contentEditable={false}
                key={`attached-list-${post.post_id}`}
                attachedFiles={{
                  name: post.file && post.file.file_name,
                  type: post.file && post.file.type
                }}
              />
            ) : (
              <Stack direction="row" gap={1}>
                <Avatar>
                  <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '100%', minWidth: '48px' }}
                  />
                </Avatar>
                <Stack>
                  <Skeleton variant="text" width={90} />
                  <Skeleton variant="text" width={130} />
                </Stack>
              </Stack>
            )}
          </CardContent>
          <CardActions
            sx={{
              position: 'relative',
              pt: '0 !important',
              justifyContent: 'space-between'
            }}
            disableSpacing
          >
            <Stack direction="row" gap={1} alignItems="center">
              <FavoritePostButton
                post={{ ...post, postDetails }}
                key={`favorite-btn-${post.post_id}`}
                handleFavoriteItem={handleFavoritePostItem}
              />
              {post.signature_required && (
                <>
                  <Divider
                    orientation="vertical"
                    sx={{ minHeight: 30, height: '100%' }}
                  />
                  <Stack direction="row" gap={0.5}>
                    {postDetails && !isUndefined(postDetails.signCount) ? (
                      <Typography
                        variant="body2"
                        sx={{
                          lineHeight: 3,
                          userSelect: 'none',
                          cursor: (isMyPost || isOrg) && 'pointer'
                        }}
                        onClick={() => {
                          const iRankHigher =
                            compareRolesOrdersOfAnyOfTheDepartments();
                          console.log(iRankHigher);
                          if (isMyPost || isOrg || iRankHigher) {
                            handleOpenPostSignaturesList(post);
                          }
                        }}
                      >
                        {getSignCountPhrase()}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" width={60} />
                    )}
                  </Stack>
                </>
              )}
            </Stack>
            {post.signature_required && (
              <>
                {isUserRequiredToSign() ? (
                  <Button
                    key={`sign-btn-${post.post_id}`}
                    variant="outlined"
                    color="success"
                    fullWidth
                    sx={{
                      maxWidth: 120
                    }}
                    size="small"
                    onClick={() =>
                      handleViewCommunityAttachedFile({ ...post, postDetails })
                    }
                  >
                    צפיה וחתימה
                  </Button>
                ) : (
                  <Button disabled>אינך נדרש לחתום</Button>
                )}
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

export default CommunityPostItemCard;
